
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { useStore } from 'vuex';
import { GET_ORDER_DASHBOARD, GET_ORDER_LISTS, SEARCH_ORDER_LISTS, GET_ORDER_STATUS } from '../graphql/Queries';
import { DELETE_ORDER, CANCEL_ORDER } from '../graphql/Mutations';
// import Search from "@/components/search/Search.vue";
import { useI18n } from 'vue-i18n';
import Table from '../../../components/Table/Table.vue';
import { useRouter } from 'vue-router';
import { setPageHistory } from '@/core/helpers/toolbar';
import InnerLoader from '../../../components/InnerLoader.vue';
// import { dropdown_handler } from "../../../core/helpers/dropdownHandler";
import AdvanceSearch from '../../../components/search/AdvanceSearch.vue';
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
    name: 'Orders',
    components: {
        // Search,
        Table,
        InnerLoader,
        AdvanceSearch,
        ContentLoader
    },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const loader = ref(false);
        const loading = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const orders: any = ref([]);
        const dashboard = ref({}) as Record<any, any>;
        const system_locale = ref();
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const showSearchBar = ref(false);
        const router = useRouter();
        const permissions = store.getters.getPermissions;
        const activeIndex = ref(-1);
        const columnName = ref(null);
        const columnSort = ref(null);
        const total_revenue = ref(null);
        const filterOption = ref({}) as Record<any, any>;
        const search_order_query = ref(false);
        const showDelete = ref(false);

        filterOption.value = {
            order_status: true,
            date_from: true,
            date_to: true,
            economic_number_order: true,
            name_order: true,
            order_id: true,
            date_filter: true
        };

        const statusList = ref([]) as Record<any, any>;
        const orderStatusList = ref([]) as Record<any, any>;

        const columns = ref([
            {
                label: 'message.ORDER_ID',
                key: 'id',
                sorting: true
            },
            {
                label: 'message.CUSTOMERS',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.E_CONOMIC',
                key: 'economic'
            },
            {
                label: 'message.SHIPMENT',
                key: 'shipment'
            },
            {
                label: 'message.TOTAL',
                key: 'total',
                textAlignment: 'end'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at',
                sorting: true
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
            statusList.value = [];
            orderStatusList.value.forEach((element, index) => {
                statusList.value.push({
                    label: JSON.parse(element?.status)[system_locale.value]?.name,
                    value: element.id
                });
            });
        });

        const orderDashboard = () => {
            search_order_query.value = true;
            const searchData = store.getters.getSearchData;
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_ORDER_DASHBOARD,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    filter: JSON.stringify(searchData?.data?.select),
                    search_key: searchData?.data?.input,
                    locale: system_locale.value,
                    showDeleted: showDelete.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                dashboard.value = JSON.parse(data.orders_dashboard);
                // loader.value = false;
            });
        };

        const searchHandler = (page = 0, reload = false, colName = null, type = null) => {
            search_order_query.value = true;
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            const searchData = store.getters?.getSearchData;
            loader.value = true;
            orderDashboard();
            Apollo.watchQuery({
                query: SEARCH_ORDER_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: JSON.stringify(searchData?.data?.select),
                    search_key: searchData?.data?.input,
                    locale: system_locale.value,
                    showDeleted: showDelete.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                clickHandler.value = searchHandler;
                orders.value = [];
                orders.value = data.search_orders.data;
                pagination.value = data.search_orders;
                loader.value = false;
            });
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const Orders = (page = 0, reload = false, colName = null, type = null, showDeleted = false) => {
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            search_order_query.value = false;
            orderDashboard();
            Apollo.watchQuery({
                query: GET_ORDER_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    // col: colName,
                    // type: type == true ? 'asc' : 'desc',
                    showDeleted: showDeleted
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = Orders;
                orders.value = data.orders.data;
                pagination.value = data.orders;
                loader.value = false;
            });
        };

        const handleView = (order: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({
                name: 'order_details',
                params: { uuid: order.uuid }
            });
        };

        const handleDelete = (id: Record<any, any>) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_ORDER,
                        variables: { id: id },
                        update: (store, { data: { delete_order } }) => {
                            searchHandler(pagination.value.current_page, false, columnName.value, columnSort.value);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const handleCancel = (id: Record<any, any>) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_CANCEL_ORDER')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: CANCEL_ORDER,
                        variables: { id: id },
                        update: (store, { data: { cancel_order } }) => {
                            searchHandler(pagination.value.current_page, false, columnName.value, columnSort.value);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_UPDATED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const exportFile = (data, showDeleted = false) => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_ORDER_LISTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: JSON.stringify(searchData?.data?.select ? searchData?.data?.select : ''),
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: system_locale.value,
                    types: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo,
                    showDeleted: showDelete.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_orders?.data[0]?.path, '_blank');
                    loading.value = false;
                }
                loading.value = false;
                sub.unsubscribe();
            });
        };

        const orderStatus = () => {
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_ORDER_STATUS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only'
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                if (data?.orders_status.length > 0) {
                    orderStatusList.value = JSON.parse(data?.orders_status);
                }

                // loader.value = false;
            });
        };

        const getOrders = (page, reload, colName = null, type = null, showDeletedOrders = false) => {
            Orders(page, reload, colName, type, showDeletedOrders);
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);

            if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
                searchHandler(pageHistory.page_number, false, columnName.value, columnSort.value);
            } else {
                pageHistory.page_number == undefined ? getOrders(0, true, null, null, showDelete.value) : getOrders(pageHistory.page_number, false, null, null, showDelete.value);
            }
            orderStatus();
        });

        const handleCurrentChange = page_number => {
            clickHandler.value(page_number);
        };

        const changeShowDelete = () => {
            const pageHistory = store.getters.getPageHistory;
            searchHandler(pageHistory.page_number, false, columnName.value, columnSort.value);
        };

        return {
            orders,
            pagination,
            loading,
            dashboard,
            clickHandler,
            currentPage,
            showSearchBar,
            system_locale,
            columns,
            permissions,
            loader,
            exportFile,
            handleView,
            handleCurrentChange,
            searchHandler,
            getOrders,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            columnName,
            columnSort,
            filterOption,
            statusList,
            handleDelete,
            showDelete,
            changeShowDelete,
            total_revenue,
            handleCancel
        };
    }
});
