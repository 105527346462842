import { gql } from "graphql-tag";

export const DELETE_ORDER = gql`
	mutation DeleteOrder($id: Int!) {
		delete_order(id: $id)
	}
`;

export const CANCEL_ORDER = gql`
	mutation CancelOrder($id: Int!) {
		cancel_order(id: $id)
	}
`;

export default { DELETE_ORDER, CANCEL_ORDER };
